<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-350px">
            <div>
                <p class="title">미신고자 SMS 보내기</p>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="90">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>받는 분</th>
                            <td>{{ pop.to_txt }}</td>
                        </tr>
                        <tr>
                            <th><span class="txt-red">*</span> 내용</th>
                            <td>
                                <textarea @change="pop.calcMessage" @keydown="pop.calcMessage" @keyup="pop.calcMessage" v-model.trim="pop.message" :maxlength="pop.max_len" class="w-100per h-150px"></textarea>
                                <div class="txt-right pt-5 pb-5">{{ pop.max_len>90?'장문':'단문' }} {{ pop.cur_len }}/{{ pop.max_len }}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="txt-center pt-20 pb-20">
                    <button type="button" @click="pop.doSubmit" class="btn-default dp-inblock">보내기</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'

export default {
    name: 'SendSMSReportPopup',
    props: {
        modelValue: {
            type: [Object, String],
            default: "",
        }
    },
    setup(props, {emit}) {
        const toast = useToast()

        const pop = reactive({
            selected : [],
            from     : "02-518-0036",
            to       : [],
            to_txt   : "",
            message  : "",
            cur_len  : 0,
            max_len  : 90,

            calcMessage : () => {
                let byte = 0;
                let str = pop.message;

                for (var i=0; i<str.length; ++i) {
                    (str.charCodeAt(i) > 127) ? byte += 2 : byte++ ;
                }
                pop.cur_len = byte;

                if( pop.cur_len > 90 ){
                    pop.max_len = 2000;
                } else {
                    pop.max_len = 90;
                }

            },

            doSubmit: () => {
                let params = {
                    from    : pop.from,
                    to      : pop.to,
                    message : pop.message
                };

                if( !params.from ){
                    toast.error("발송 번호를 입력하세요.");
                    return;
                }

                if( pop.cur_len > 2000 ){
                    toast.error("최대 2000바이트 이하로 입력하세요.");
                    return;
                }

                Swal.fire({
                    title : "SMS 발송",
                    html  : "발송하시겠습니까?<br>Mobile 데이터가 없는 경우 SMS가 발송되지 않습니다.",
                    showCancelButton: true
                }).then((res) => {
                    if (res.isConfirmed) {
                        axios.post("/rest/component/sendSMS", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("발송하였습니다.");
                                pop.onClose();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            },

            doSearch: () => {
                let params = {
                    selected : pop.selected
                };
                axios.get("/rest/mypims/getPhone", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;

                        if( pop.total > 1 ){
                            pop.to_txt = pop.list[0].kname + " 외 " + (pop.total-1) + "명";
                        } else {
                            pop.to_txt = pop.list[0].kname;
                        }
                        for(let i=0; i<pop.list.length; i++){
                            let irow = pop.list[i];
                            if( irow.phone ){
                                pop.to.push(irow.phone);
                            }
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            onClose: () => {
                pop.selected=[];
                 emit('close');
            }
        });

        watch(() => pop.from, (nVal) => {
            if( nVal ){
                pop.from = nVal.toString().replace(/[^0-9]/gi, '');
            }
        });

        onMounted(() => {
            // Mounted
            pop.selected = props.modelValue;

            if( pop.selected.length > 0 ){
                pop.doSearch();
            }
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.pop-body { 
    text-align: center;
    p {
        font-size:18px; padding:20px 0;
    }
}
</style>