<template>
    <div class="con-wrap">
        <SendSMSReportPopup v-model="assets.selected" v-if="assets.isShowSMS" @close="assets.isShowSMS=false"></SendSMSReportPopup>
        <SendSMSReportAllPopup v-if="assets.isShowSMSAll" @close="assets.isShowSMSAll=false"></SendSMSReportAllPopup>

        <CarrotTitle title="재물 조사 여부 관리">
            - 재물 조사 신고여부를 확인 및 SMS 보내기가 가능합니다.
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div class="mt-50 mb-20">
                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">분류</th>
                                <td>
                                    <select name="cat1" id="cat1" class="w-45per mr-10" v-model="assets.cat1" @change="assets.setCat1">
                                        <option value="">중분류 전체</option>
                                        <option :value="irow" v-for="(irow, i) in assets.categories" :key="i">{{ irow.name }}</option>
                                    </select>
                                    <select name="cat2" id="cat2" class="w-45per" v-model="assets.cat2" @change="assets.doSearch">
                                        <option value="">소분류 전체</option>
                                        <option :value="v" v-for="(v, k) in assets.cat1.children" :key="k">{{ v }}</option>
                                    </select>
                                </td>
                                <th width="180">구입일</th>
                                <td>
                                    <CarrotDatePicker v-model="assets.sdate" class="w-110px float-left"></CarrotDatePicker>
                                    <span class="float-left ml-10 mr-10">~</span>
                                    <CarrotDatePicker v-model="assets.edate" class="w-110px float-left"></CarrotDatePicker>
                                </td>
                            </tr>
                            <tr>
                              <th width="180">사용자</th>
                              <td>
                                <carrot-dept v-model="assets.idx_office" class="w-45per mr-10"></carrot-dept>
                                <carrot-staff :idx_office="assets.idx_office" v-model="assets.idx_hq" class="w-45per"></carrot-staff>
                              </td>
                                <th width="180">지급일</th>
                                <td>
                                  <CarrotDatePicker v-model="assets.payment_sdate" class="w-110px float-left"></CarrotDatePicker>
                                  <span class="float-left ml-10 mr-10">~</span>
                                  <CarrotDatePicker v-model="assets.payment_edate" class="w-110px float-left"></CarrotDatePicker>
                                </td>
                            </tr>
                            <tr>
                                <th width="180">시리얼</th>
                                <td>
                                  <input type="text" name="serialno" class="w-100per" v-model.trim="assets.serialno">
                                </td>
                                <th width="180">자산번호</th>
                                <td>
                                    <input type="text" name="codename" class="w-100per" v-model.trim="assets.codename">
                                </td>
                            </tr>
                          <tr>
                            <th width="180">모델명</th>
                            <td>
                              <input type="text" name="title" class="w-100per" v-model.trim="assets.model">
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-right mt-10" @click="assets.doSearch()">검색</button>
                    <div class="clear"></div>
                </div>

                <div class="mt-40 mb-20">
                    <select name="usage" id="usage" class="w-200px" v-model="assets.usage" @change="assets.doSearch">
                        <option value="">용도 전체</option>
                        <option value="대여용">대여용</option>
                        <option value="개인용">개인용</option>
                        <option value="부서용">부서용</option>
                    </select>
                    <select name="state" id="state" class="w-200px ml-10" v-model="assets.state" @change="assets.doSearch">
                        <option value="">보유+지급 전체</option>
                        <option value="보유">보유</option>
                        <option value="지급">지급</option>
                        <option value="폐기">폐기</option>
                    </select>
                  <button @click="assets.showSMS" class="btn-default float-right h-30px ml-10">미신고자 SMS 보내기</button>
                    <div class="clear"></div>
                </div>
                
                <table class="table-col">
                    <colgroup>
                        <col width="40">
                        <col width="45">
                        <col width="110">
                        <col width="80">
                        <col width="100">
                        <col width="*">
                        <col width="95">
                        <col width="95">
                        <col width="95">
                        <col width="95">
                        <col width="60">
                        <col width="60">
                        <col width="95">
                        <col width="95">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <label><input type="checkbox" v-model="assets.checkAll" @change="assets.doCheckAll"></label>
                            </th>
                            <th>No</th>
                            <th>자산번호</th>
                            <th>분류</th>
                            <th>모델명</th>
                            <th>시리얼</th>
                            <th>구입일</th>
                            <th>반출일</th>
                            <th>수령일</th>
                            <th>사용자</th>
                            <th>용도</th>
                            <th>상태</th>
                            <th>폐기일</th>
                            <th>신고완료일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(irow, i) in assets.list" :key="i">
                          <td v-if="irow.report_date != '-' || irow.idx_hq ==0"><label><input type="checkbox" disabled></label></td>
                          <td v-else> <input type="checkbox" v-model="irow.checked" ></td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.num }}</td>
                            <td v-else class="pointer"  @click="assets.doCheck(i)">{{ irow.num }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" @click="assets.showDetail(irow.idx)"  class="txt-ellipsis" :title="irow.code"><span class="btn-view">{{ irow.code }}</span></td>
                            <td v-else class="pointer txt-ellipsis"  @click="assets.doCheck(i)"><span class="btn-view">{{ irow.code }}</span></td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.cat2">{{ irow.cat2 }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.cat2 }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.model">{{ irow.model }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.model }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.serialno">{{ irow.serialno }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.serialno }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.buy_date }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.buy_date }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.gifted_date }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.gifted_date }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.receipt_date }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.receipt_date }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.idx_hq>0?irow.ename+'('+irow.kname+')':'-' ">{{ irow.idx_hq>0?irow.ename+'('+irow.kname+')':'-' }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.idx_hq>0?irow.ename+'('+irow.kname+')':'-' }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.usage">{{ irow.usage }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.usage }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0" class="txt-ellipsis" :title="irow.state">{{ irow.state }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.state }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.deletedate }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.deletedate }}</td>
                            <td v-if="irow.report_date != '-' || irow.idx_hq ==0">{{ irow.report_date }}</td>
                            <td v-else class="pointer txt-ellipsis" @click="assets.doCheck(i)">{{ irow.report_date }}</td>
                        </tr>
                        <tr v-if="assets.total==0">
                            <td colspan="12">검색 결과가 없습니다.</td>
                        </tr>
                    </tbody>
                </table>
                <CarrotPaging :total="assets.total" :list_per_page="assets.rows" v-model="assets.page" @change="assets.doSearch"></CarrotPaging>

            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotStaff from '@/components/common/CarrotStaff.vue'
import { useStore } from 'vuex';
import SendSMSReportPopup from '@/components/popup/customerManagement/SendSMSReportPopup.vue'
import SendSMSReportAllPopup from '@/components/popup/customerManagement/SendSMSReportAllPopup.vue'

export default {
    layout:"myPIMS",

    components: {
        CarrotDatePicker,
        CarrotDept,
        CarrotStaff,
        SendSMSReportPopup,
        SendSMSReportAllPopup
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const assets = reactive({
            categories : [],

            checkAll:false,
            page   : 1,
            rows   : 20,

            cat1   : "",
            cat2   : "",

            sdate  : "",
            edate  : "",
            payment_sdate  : "",
            payment_edate  : "",
            idx_office : "0",
            idx_hq     : "",

            serialno : "",
            codename : "",
            model    : "",

            usage        : "",
            state        : "",

            selected : [],

            list : [], total : 0,
            isShowSMS : false,
            isShowSMSAll:false,
            showSMS : () => {
              assets.selected=[];
              var check_cnt=0;
              for(let i=0;i<assets.list.length;i++) {
                if(assets.list[i].checked){
                  if( typeof assets.list[i].hq_idx !== 'undefined' ){
                    assets.selected[check_cnt]=assets.list[i].hq_idx;
                    check_cnt=check_cnt+1;
                  }
                }
              }

              if( assets.selected.length > 0 ){
                assets.isShowSMS = true;
              } else {
                assets.isShowSMSAll = true;
              }
            },
            doCheckAll : () => {
              let val = false;
              if(assets.checkAll == true) {
                val = true;
              }
              for(let i=0;i<assets.list.length;i++) {
                if(assets.list[i].report_date == '-' &&assets.list[i].idx_hq !=0)
                  assets.list[i].checked = val;
              }
            },
            doCheck : (i) => {
              if(assets.list[i].checked==false) assets.list[i].checked=true;
              else assets.list[i].checked=false;
            },
            showDetail: (idx) => {
                router.push({
                    name   : 'myPIMS-AssetManagementEdit-idx',
                    params : { idx:idx, page:assets.page }
                });
            },

            setCat1 : () =>{
                assets.cat2 = "";
            },

            getCategory : () => {
                axios.get("/json/rental_category.json", {}).then((res) => {
                    if( res.status == 200 ){
                        assets.categories = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch: () => {
                let params = {
                    page   : assets.page,
                    rows   : assets.rows,

                    sdate  : assets.sdate,
                    edate  : assets.edate,

                    payment_sdate  : assets.payment_sdate,
                    payment_edate  : assets.payment_edate,

                    idx_office : assets.idx_office,
                    idx_hq       : assets.idx_hq,

                    serialno : assets.serialno,
                    codename : assets.codename,
                    model    : assets.model,

                    usage : assets.usage,
                    state : assets.state
                };

                if( typeof assets.cat1 == 'undefined' || assets.cat1 == '' ){
                    params.cat1 = '';
                    params.cat2 = '';
                } else {
                    params.cat1 = assets.cat1.name;
                    if( typeof assets.cat2 == 'undefined' || assets.cat2 == '' ){
                        params.cat2 = '';
                    } else {
                        params.cat2 = assets.cat2;
                    }
                }

                axios.get('/rest/mypims/getAssetsList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        assets.list  = res.data.list;
                        assets.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

        });

        onMounted(() => {
            // Mounted
            let auth = false;
            if (store.state.isSalesAdmin || store.state.isManageSupportAdmin) //자산관리는 총무+회계
              auth = true;

            if( auth==false ) {
                router.go(-1);
                Swal.fire({
                    title : '자산관리 등록',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            assets.getCategory();
            assets.doSearch();
        });

        onUnmounted(() => { 
            // UnMounted
        });

        return {assets};
    }
}
</script>

<style lang="scss" scoped>
</style>